import type {ReactElement, Ref} from 'react';
import React from 'react';
import {IconSize} from '@Components/icon-v2/icon.types';
import {DROPDOWN_TEXT_TYPE, DropdownText} from '@Components/dropdown-v2/components/dropdown-text';
import styles from './dropdown-default-select-box.module.scss';
import type {BaseDropdownSelectBox} from '../../dropdown.types';
import { DROPDOWN_SIZE} from '../../dropdown.types';
import {Icon} from '../../../icon-v2';
import {CircularProgressLoader, LOADER_COLOR, LOADER_SIZE} from '../../../circular-progress-loader';

interface DropdownDefaultSelectBoxProps extends BaseDropdownSelectBox {
  label?: string;
  information?: string;
  description?: string;
  hideCaret?: boolean;
  openedClassName?: string;
  isBold?: boolean;
  iconClassName?: string;
}

export const DropdownDefaultSelectBox = React.forwardRef(
  (
    {
      id = '',
      isOpened = false,
      text = '',
      icon = '',
      svg = undefined,
      description = '',
      information = '',
      label = '',
      className = '',
      onClick = (): void => {},
      size = DROPDOWN_SIZE.SMALL,
      isDisabled = false,
      isLoading = false,
      hideCaret = false,
      openedClassName = '',
      textClassName = '',
      isBold = false,
      iconClassName = '',
    }: DropdownDefaultSelectBoxProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const onClickHandler = (): void => {
      if (!isDisabled && !isLoading) {
        if (onClick) {
          onClick(id);
        }
      }
    };

    const getPaddingForSelectBox = (): string => {
      if (description) {
        return styles.selectorPaddingWithDescription;
      }
      if (size === DROPDOWN_SIZE.XSMALL) {
        return styles.selectorXSmallPadding;
      }
      if (size === DROPDOWN_SIZE.SMALL) {
        return styles.selectorSmallPadding;
      }
      if (size === DROPDOWN_SIZE.MEDIUM) {
        return styles.selectorMediumPadding;
      }
      return '';
    };

    const getIconSize = (): IconSize => {
      switch (size) {
        case DROPDOWN_SIZE.XSMALL:
          return IconSize.SIZE_ICON_16;
        case DROPDOWN_SIZE.SMALL:
          return IconSize.SIZE_ICON_20;
        case DROPDOWN_SIZE.MEDIUM:
          return IconSize.SIZE_ICON_24;
        default:
          return IconSize.SIZE_ICON_20;
      }
    };

    const getLoaderSize = (): LOADER_SIZE => {
      switch (size) {
        case DROPDOWN_SIZE.XSMALL:
          return LOADER_SIZE.XSMALL;
        case DROPDOWN_SIZE.SMALL:
          return LOADER_SIZE.SMALL;
        case DROPDOWN_SIZE.MEDIUM:
          return LOADER_SIZE.SMALL;
        default:
          return LOADER_SIZE.SMALL;
      }
    };

    const getLabel = (): ReactElement | null => {
      if (label) {
        return <DropdownText text={label} type={DROPDOWN_TEXT_TYPE.LABEL_TEXT} isBold isDisabled={isDisabled} size={size} />;
      }
      return null;
    };

    const getDescription = (): ReactElement | null => {
      if (description) {
        return <DropdownText text={description} size={size} type={DROPDOWN_TEXT_TYPE.DESCRIPTON_TEXT} />;
      }
      return null;
    };

    const getSelectBoxContainerClasses = (): string => {
      return [
        styles.dropdownHeader,
        getPaddingForSelectBox(),
        'flex-justify-between',
        'flex-items-center',
        label ? 'spacing-m-t-2' : '',
        isOpened ? `${styles.opened} ${openedClassName}` : '',
        className,
        isDisabled ? styles.disabled : '',
        'hover-transition-all',
      ].join(' ');
    };

    const getInformationContainer = (): ReactElement | null => {
      if (information) {
        return (
          <div className={`flexbox flex-items-center ${styles.infoContainer}`}>
            <DropdownText svg={svg} text={information} size={size} type={DROPDOWN_TEXT_TYPE.INFORMATION_TEXT} isDisabled={isDisabled} icon="icon-info" />
          </div>
        );
      }
      return null;
    };

    const getDropdownCarets = (): ReactElement | null => {
      if (isLoading) {
        return <CircularProgressLoader size={getLoaderSize()} color={LOADER_COLOR.DARK} />;
      }

      if (hideCaret) {
        return null;
      }

      if (isOpened) {
        return <Icon icon="icon-caret-up" size={getIconSize()} className={`${isDisabled ? 'content-disabled' : ''}`} />;
      }

      return <Icon icon="icon-caret-down" size={getIconSize()} className={`${isDisabled ? 'content-disabled' : ''}`} />;
    };

    return (
      <>
        {getLabel()}
        <div ref={ref} id={id} className={getSelectBoxContainerClasses()} onClick={onClickHandler}>
          <div className={`flex-v-row flex-1 ${styles.selectorTextBox} ${hideCaret ? 'spacing-p-r-1' : ''}`}>
            <div className="flex-row-align-center">
              <DropdownText
                text={text}
                size={size}
                type={DROPDOWN_TEXT_TYPE.SELECTOR_TEXT}
                icon={icon}
                iconClassName={iconClassName}
                isDisabled={isDisabled}
                textClassName={textClassName}
                isBold={isBold}
              />
            </div>
            {getDescription()}
          </div>
          {getDropdownCarets()}
        </div>
        {getInformationContainer()}
      </>
    );
  }
);
